import React, { lazy, Suspense } from "react";
import CustomNav from "./NavBar";
import { Container, Row, Col, Spinner, CardGroup } from "react-bootstrap";
import {  backEndSkillsList,frontEndSkillsList,dataBaseSkillsList,cloudSkillsList } from "../utils/helperList";

const IconCard = lazy(() => import("../utils/IconCard"));

const Skills = () => {

  return (
    <>
      
      <Container>
        <CustomNav />
        <div>
        <Suspense fallback={<div className="d-flex justify-content-center "><Spinner animation="border" variant="secondary" /></div>}>
          <Row xs={12} md={4}>
            <Col  md={6}>
              <div className="mt-4">
                <h2 className="color-custom-blue ml-lg-5 proj-slide mt-5">Front End Skills</h2>
                <CardGroup className=" skill-icons-align">
                    {frontEndSkillsList.map(skill => (
                        <IconCard key={skill.label} src={skill.src} label={skill.label} />
                      ))}
                </CardGroup>
                </div>
            </Col>
            <Col  md={6}>
              <div className="mt-4">
                <h2 className="color-custom-blue ml-lg-5 proj-slide mt-5">Back End Skills</h2>
                <CardGroup className="contact skill-icons-align">
                    {backEndSkillsList.map(skill => (
                        <IconCard key={skill.label} src={skill.src} label={skill.label} />
                      ))}
                </CardGroup>
              </div>
            </Col>
          </Row>

          <Row xs={12} md={4}>
            <Col  md={6}>
              <div className="mt-5">
                <h2 className="color-custom-blue ml-lg-5 proj-slide mt-5">Api Skills</h2>
                <CardGroup className="contact skill-icons-align">
                      {dataBaseSkillsList.map(skill => (
                        <IconCard key={skill.label} src={skill.src} label={skill.label} />
                      ))}
                </CardGroup>
              </div>
            </Col>
            <Col  md={6}>
              <div className="mt-5">
                <h2 className="color-custom-blue ml-lg-5 proj-slide mt-5">Cloud Skills</h2>
                <CardGroup className="contact skill-icons-align">
                      {cloudSkillsList.map(skill => (
                        <IconCard key={skill.label} src={skill.src} label={skill.label} />
                      ))}
                </CardGroup>
              </div>
            </Col>
          </Row>
        </Suspense>
        </div>
      </Container>
    </>
  )
};

export default Skills;
